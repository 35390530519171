export default defineNuxtRouteMiddleware(async (to, from) => {
    const config = useRuntimeConfig();

    if(!config.public.isMiddlewareProtectionEnabled || !config.public.mockApiBaseUrl) {
        return ;
    }

    const access_token = useCookie('TOKEN_EU24WEB_DEV');

    if(useRootStore().isBuilderPreview(to)){
        return ;
    }


    if(!access_token.value) {
        return navigateTo('/protected');
    } else {
        // Get current date
        let currentDate = new Date();

        // Calculate 7 days from now
        let expirationDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));

        const authTokenDate = useCookie('TOKEN_VALIDATION_LAST_DATE_EU24WEB_DEV', {
            maxAge: (expirationDate.getTime() - currentDate.getTime()) / 1000
        });

        const token_validation_date_difference = new Date() - new Date(authTokenDate.value);

        if(token_validation_date_difference > 1000 * 60 * 60 * 24) {
            // token validation body
            const body = {
                _token: access_token.value
            }

            let options = {
                method: 'POST',
                params:body,
                show_notifications:false,
                show_loading_indicator:false,
            }

            let is_token_validate = await useAjax('/api/token-validate',options);

            if(is_token_validate.is_expired){
                console.log('access_token expired',access_token.value)
                access_token.value = '';
                authTokenDate.value = '';
                return navigateTo('/protected');
            }

            if(is_token_validate.success) {
                authTokenDate.value = new Date();
            } else {
                console.log('access_token failed',access_token.value)
                return navigateTo('/protected');
            }
        }
    }
})